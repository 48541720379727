<template>
  <div class="auth-layout">
    <notifications />

    <div class="header">
      <authHeader />
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
    <div class="footer">
      <authFooter />
    </div>
  </div>
</template>

<script>
import authHeader from './AuthHeader.vue'
import authFooter from './AuthFooter.vue'
export default {
  components: {
    authHeader,
    authFooter
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
